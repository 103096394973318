.jumbotron {
  padding: 20px;
  margin: 2px 0;
}

.table {
  margin: auto;
  max-width: 700px;
}

.table th,
.table td {
  padding: 5px;
  vertical-align: middle;
}
