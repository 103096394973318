:root {
  --primary-color: #3a4052;
  --ud-green: #20c4b5;
  --ud-red: #d7877e;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}
